import React, { useState, useEffect,useCallback } from "react";
import { Helmet } from "react-helmet";
import { FiEdit2 } from "react-icons/fi";
import { HiDotsVertical } from "react-icons/hi";
import { BsEmojiSmile, BsCardImage } from "react-icons/bs";
import EmojiPicker from "emoji-picker-react"; // Import emoji picker
import * as Styled from "./style";
import UserImg from "../../assets/user1.png";
import DefaultImg from "../../assets/header/default.png";
import { useAppContext } from "../../context/AppContext";
import { IoIosClose } from "react-icons/io";

const Chat = () => {
  const [isDetail, setIsDetail] = useState(false);
  const [selected, setSelected] = useState(0);
  const [ws, setWs] = useState(null);
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [image, setImage] = useState(null); // State for the selected image
  const [showModal, setShowModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null); // State for the selected user from search results
  const [chatList, setChatList] = useState([]); // New state for the chat list
  const [isLoading, setIsLoading] = useState(false);
  const [activeAdd, setActiveAdd] = useState(true);
  const [context, setContext] = useAppContext();


  const webSocketUrl =
  process.env.NODE_ENV === "production"
    ? "wss://api.galambo.com" // replace with your production WebSocket URL
    : "ws://localhost:4000";
  const onMessageHandler = useCallback((event) => {
    try {
      const data = JSON.parse(event.data);
      if (data.to === context.auth.user.id || data.from === context.auth.user.id) {
        setMessages((prev) => [...prev, data]);
      }
    } catch (error) {
      console.error("Error parsing message:", error);
    }
 }, [context.auth.user.id]);
useEffect(() => {
  const webSocket = new WebSocket(webSocketUrl);
  webSocket.onopen = () => {
    webSocket.send(JSON.stringify({ type: "init", userId: context.auth.user.id }));
  };
  webSocket.onmessage = onMessageHandler;  // Set the message handler
  setWs(webSocket);
  fetchChatList();
  return () => {
    webSocket.close();
  };
}, [context.auth.user.id, onMessageHandler]);

  const baseUrl =
    process.env.NODE_ENV === "production"
      ? "https://api.galambo.com" // replace with your production server URL
      : "http://localhost:4000";

  const fetchChatList = async () => {
    try {
      const response = await fetch(
        `${baseUrl}/chat/chatlist/${context.auth.user.id}`
      );
      const data = await response.json();

      setChatList(data);
    } catch (error) {
      console.error("Error fetching chat list:", error);
    }
  };

  const fetchMessages = async (userId) => {
    try {
      const response = await fetch(
        `${baseUrl}/chat/messages?from=${context.auth.user.id}&to=${userId}`
      );
      const data = await response.json();
      setMessages(data); // Set fetched messages to state
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  };
 // Fetch messages when the selected chat changes
 useEffect(() => {
  if (chatList.length > 0 && selected < chatList.length) {
    fetchMessages(chatList[selected].chatWith._id);
  }
}, [selected, chatList])

  const sendMessage = async () => {
    if (ws && ws.readyState === WebSocket.OPEN && (message || image) && chatList[selected]) {
      const chatMessage = {
        from: context.auth.user.id,
        to: chatList[selected].chatWith._id,
        message: message || '',
        image: image || null, // Include image if provided
    };
      setIsLoading(true);
      try {
        // Send through WebSocket
        ws.send(JSON.stringify(chatMessage));
  
        // Save message to the backend
        const response = await fetch(`${baseUrl}/chat/message/save`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(chatMessage),
        });
  
        if (!response.ok) throw new Error("Failed to save message to the server");
  
        // Update message state after successful save
        setMessages((prev) => [...prev, chatMessage]);
        setMessage("");
        setImage(null);
      } catch (error) {
        console.error("Error sending message:", error);
        alert("Failed to send message. Please try again.");
      } finally {
        setIsLoading(false);
      }
    }
  };
  

  const onEmojiClick = (emojiObject) => {
    setMessage((prev) => prev + emojiObject.emoji); // Append emoji to the message
    setShowEmojiPicker(false);
  };
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const maxSize = 5 * 1024 * 1024; // 5MB in bytes
  
      if (file.size > maxSize) {
        alert("File size exceeds 5MB. Please upload a smaller image.");
        return;
      }
  
      // Resize image before uploading (you can use libraries like "pica" or "canvas")
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result); // Store base64-encoded string
      };
      reader.readAsDataURL(file);
    }
  };
  

  const handleSearch = async (query) => {
    setSearchQuery(query);

    if (query.length > 0) {
      try {
        const response = await fetch(
          `${baseUrl}/users/searches?query=${query}`
        );
        const data = await response.json();
        setSearchResults(data); // Update search results with backend data
      } catch (error) {
        console.error("Error fetching search results:", error);
      }
    } else {
      setSearchResults([]); // Clear results if query is empty
    }
  };

  const handleInputChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query); // Update search query
    handleSearch(query);
  };

  const handleUserSelect = (user) => {
    setSelectedUser(user);
    setActiveAdd(false);
  };

  const addChatUser = async () => {
    if (
      selectedUser &&
      !chatList.some((user) => user.chatWith._id === selectedUser._id)
    ) {
      try {
        const response = await fetch(`${baseUrl}/chat/friend-request`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ from:  context.auth.user.id, to: selectedUser._id }),
        });
  
        if (!response.ok) {
          const errorData = await response.json();
          alert(errorData.error || "Error sending friend request");
          return;
        }
  
        alert("Friend request sent successfully!");
      } 
        // await fetch(`${baseUrl}/chat/addChat`, {
        //   method: "POST",
        //   headers: { "Content-Type": "application/json" },
        //   body: JSON.stringify({
        //     userId1: context.auth.user.id,
        //     userId2: selectedUser._id,
        //   }),
        // });

        // // Refresh the chat list after adding a new user
        // fetchChatList();
       catch (error) {
        console.error("Error saving chat user:", error);
      }

      // Clear modal and search states
      setShowModal(false);
      setSearchQuery("");
      setSearchResults([]);
      setSelectedUser(null);
    }
  };

  // New function to handle key down events
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent the default action (new line)
      sendMessage(); // Send the message
    }
  };
  const clearModalState = () => {
    setSearchQuery(""); // Clear search query
    setSearchResults([]); // Clear search results
    setSelectedUser(null); // Deselect any selected user
    setActiveAdd(true); // Disable the chat button
    setShowModal(false); // Close the modal
  };
  return (
    <React.Fragment>
      <Styled.ChatWrapper>
        <Helmet>
          <title>Chat Page | Galambo</title>
          <meta name="description" content="Chat page of galambo" />
          <link rel="canonical" href="https://www.galambo.com/message" />
        </Helmet>
        <Styled.ChatContainer>
          <Styled.UserListContainer>
            <Styled.userListHeader>
              <h1>Messages</h1>

              <FiEdit2
                onClick={() => setShowModal(true)}
                style={{ cursor: "pointer" }}
              />
            </Styled.userListHeader>
            <Styled.UserListContent>
              {chatList && chatList.length > 0 ? ( // Check if chatList is not null and has users
                chatList.map((user, key) => (
                  <div
                    key={user.chatWith._id}
                    className={selected === key ? "active-chat" : ""}
                    onClick={() => setSelected(key)}
                  >
                    <img src={UserImg} alt="user avatar" />
                    <span>{user.chatWith.name}</span>{" "}
                    {/* Display the user's name */}
                  </div>
                ))
              ) : (
                <p>No chats available.</p> // Message if no chats
              )}
            </Styled.UserListContent>
          </Styled.UserListContainer>
          <Styled.MessageContainer>
            <Styled.MessageHeader>
              <nav>
                <div>
                  <img src={UserImg} alt="user avatar" />
                  <p>
                    {selected < chatList.length
                      ? chatList[selected].chatWith.name
                      : "Select a chat"}
                  </p>
                </div>
                <HiDotsVertical
                  size={30}
                  onClick={() => setIsDetail(!isDetail)}
                />
              </nav>
            </Styled.MessageHeader>
            <Styled.MessageContent>
              {selected < chatList.length &&
                messages
                  .filter(
                    (msg) =>
                      (msg.to === chatList[selected].chatWith._id && msg.from === context.auth.user.id) ||
                    (msg.from === chatList[selected].chatWith._id && msg.to === context.auth.user.id)
                  )
                  .map((msg, index) =>
                    msg.from === context.auth.user.id ? (
                      <Styled.SentMessage key={index}>
                        <p>{msg.message}</p>
                        {msg.image && <img src={msg.image} alt="uploaded" />}
                      </Styled.SentMessage>
                    ) : (
                      <Styled.ReceivedMessage key={index}>
                        <p>{msg.message}</p>
                        {msg.image && <img src={msg.image} alt="uploaded" />}
                      </Styled.ReceivedMessage>
                    )
                  )}
              {showEmojiPicker && <EmojiPicker style={{ position: 'absolute', bottom: '40px', right: '0' }} onEmojiClick={onEmojiClick} />}
            </Styled.MessageContent>

            <Styled.MessageInput>
              <div>
                <input
                  placeholder="Message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  onKeyDown={handleKeyDown}
                />
                <BsEmojiSmile
                  size={18}
                  onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                />
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageUpload}
                  style={{ display: "none" }}
                  id="imageUpload"
                />
                <label htmlFor="imageUpload">
                  <BsCardImage size={18} />
                </label>
                <button onClick={sendMessage}>Send</button>
              </div>
              {image && (
                <div>
                  <img
                    src={image}
                    alt="preview"
                    style={{ maxWidth: "100px", marginTop: "5px" }}
                  />
                </div>
              )}
            </Styled.MessageInput>
          </Styled.MessageContainer>
          {isDetail && (
            <Styled.DetailContainer>
              <Styled.DetailHeader>
                <h1>Details</h1>
              </Styled.DetailHeader>
              <Styled.DetailContent>
                <p>Block</p>
                <p>Delete Chat</p>
              </Styled.DetailContent>
            </Styled.DetailContainer>
          )}

          {/* Modal for searching users */}
          {showModal && (
            <Styled.ModalOverlay>
              <Styled.ModalContent>
                <Styled.ModalHeder>
                  <Styled.ModalHeaderText>New message</Styled.ModalHeaderText>{" "}
                  {/* Centered text */}
                  <IoIosClose
                    style={{ cursor: "pointer" }}
                    onClick={clearModalState}
                    color="black"
                    size={30}
                  />{" "}
                  {/* Icon at the end */}
                </Styled.ModalHeder>

                <hr />
                <Styled.InputWrapper>
                  <Styled.InputLabel>To:</Styled.InputLabel>
                  <Styled.InputModal
                    type="text"
                    placeholder="Search"
                    value={searchQuery}
                    onChange={handleInputChange}
                  />
                </Styled.InputWrapper>
                <Styled.UserGrid>
                  {searchResults.map((user) => (
                    <Styled.UserItem
                      key={user._id}
                      onClick={() => handleUserSelect(user)}
                      className={
                        selectedUser?._id === user._id ? "selected" : ""
                      }
                    >
                      <Styled.Avatar src={DefaultImg} />
                      <Styled.UserName>{user.name}</Styled.UserName>{" "}
                      {/* Show user's name */}
                    </Styled.UserItem>
                  ))}
                </Styled.UserGrid>
                <Styled.ModalButton disabled={activeAdd} onClick={addChatUser}>
                  Chat
                </Styled.ModalButton>
              </Styled.ModalContent>
            </Styled.ModalOverlay>
          )}
        </Styled.ChatContainer>
      </Styled.ChatWrapper>
    </React.Fragment>
  );
};

export default Chat;
